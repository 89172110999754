.flex-row {
    display: flex;
    flex-direction: row;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.typography-sans {
    font-family: 'Roboto', sans-serif;
}

#my-classes {
    margin-top: 40px;
    ::-webkit-scrollbar-thumb{
        background: green;
      }
      ::-webkit-scrollbar{
          width: 15px;
      }
}

.my-classes__class-link-container {
    background-color: #07224b;
    
    padding: 8px;
    margin-top: 8px;
    margin-bottom: 24px;
    border-radius: 8px;

    max-width: fit-content;
    
    &__link {
        font-family: 'Roboto', sans-serif;
        font-size: 1.25rem;
        font-weight: 500;
        text-decoration: none;
        color: #fff;
    }
}