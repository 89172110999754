.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-collumn {
    display: flex;
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.justify-space {
    justify-content: space-between;
}

.typography-sans {
    font-family: 'Roboto', sans-serif;
}

.font-blue {
    color:#07224B;
}

.font-white {
    color: #FFF;
}


#student-info{
    font-size: 10px;
    #section-header{
        h1{
            font-size: 5em;
            font-weight: lighter;
            color: #269912;
        }
        .horizontal-divider {
            height: 1px;
            width: 100%;
            background-color: #269912;
            margin: 8px 0;
        }
    }
    #student-info-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    gap: 10px;
        #student-avatar{
            width: 100%;
            max-width: 160px;
        }
        #change-avatar{
            border-radius: 50%;    
        }
        #change-avatar:hover{
            transition: .1s;
            transform: scale(1.20);
            background-color: gray;
        }
        #student-main-info{
            width: auto;
            max-width: 40%;
            font-size: 1.6em;
        }
        #cards-container{
            font-size: 1.3em;
            flex-grow: 1;
            display: grid;
            grid-template-columns: repeat(auto-fit, 160px);
            grid-auto-rows: 160px;
            row-gap: 10px;
            justify-content: space-between;
            .card-box{
                border-radius: 5%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: #07224B;
                #it-coin{
                    width: 60px;
                }
            }
        }
    /*#student-info-row{
        display: flex;
        flex-direction: row;
        height: 220px;
        #student-photo-container{
            display: flex;
           height: 175px; 
           width: 175px;
           align-self: center;
           #student-photo{
               width: 100%;
               height: 100%;
               border-radius: 10px;
           }
        }
        #main-info-container{
            display: flex;
            flex-direction: column;
            height: 175px;
            width: 25vw;
            align-self: center;
            margin-left: 30px;
            justify-content: space-between;
            #main-info-header{
                font-size: 3.5em;
            }
            #main-info-body{
                p{
                    font-size: 2.5em;
                    hyphens: auto;
                }
            }
        }
        #extra-info-container{
            display: flex;
            flex-direction: row;
            height: 175px; 
            flex-grow: 4;
            align-self: center;
            justify-content: flex-end;
            .extra-info-box{
                display: flex;
                flex-direction: column;
                border-radius: 20px;
                height: 100%;
                width: 175px;
                margin: 0 10px;
                background-color: #07224B;
                align-items: center;
                justify-content: center;

            }
        }  
    }
    #progress{
        margin-top:20px;
        p{
            font-size: 20px;
            font-weight: bold;
        }
        #progress-bar {
            border-color:#07224B;
            border-radius: 8px;
            border-style: solid;
            border-width: 5px;
            height: 20px;
            width: 100%;
            background-color: white;
            margin-bottom: 16px;
        }
    }
    */
}

}
@media (max-width: 768px) {
    #student-info{
    font-size: 8px;
    #student-info-container{
        width: 100%;
        flex-direction: column;
        #student-main-info{
            min-width: 80%;
            text-align: center;
        }
        #cards-container{
            grid-template-columns: repeat(auto-fit, 120px);
            grid-auto-rows: 120px;
        }
    }    
    }
}