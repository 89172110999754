.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-collumn {
    display: flex;
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.justify-space {
    justify-content: space-between;
}

.typography-sans {
    font-family: 'Roboto', sans-serif;
}

.display-grid{
    display: grid;
}

#class-course-list{
    padding: 2%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(320px,1fr));
    gap: 10px;
    grid-auto-rows: auto;
    overflow: hidden;
    overflow-y: scroll;
    align-items: baseline;
    .course-card {
        display: flex;
        flex-direction: column;
        width: 320px;
        color: #000;
        text-decoration: none;

        &:hover {
            transition: .1s;
            transform: scale(1.05);
        }

        .thumbnail-frame {
            align-self: center;
        }
        
        .thumbnail {
            max-width: 320px;
            height: 180px;
            border-radius: 8px;
            margin-bottom: 8px;
        }
        
        .course-title {
            font-size: 1.563rem;
            text-transform: uppercase;
        }

        .course-description {
            margin-top: 8px;
            font-size: 1rem;
            overflow-y: hidden;
        }
    }

}
