.section-header {
    .title--space-left {
        margin-left: 16px;
    }

    &__content {
        display: flex;
        align-items: center;
        
        &__title {
            font-family: 'Roboto', sans-serif;
            font-size: 2.441rem;
            font-weight: 400;
            color: #269912;
            text-transform: uppercase;
        }
    }


    &__horizontal-divider {
        height: 1px;
        width: 100%;
        background-color: #269912;
        margin: 16px 0;
    }
}